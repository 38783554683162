import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../../components/breadcrumb'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Link from '../../components/link'
import List from '../../components/list'
import ListItem from '../../components/list-item'
import SEO from '../../components/seo'


export const query = graphql`
  query NotesSitemapPageQuery($genderId: PostGraphile_Gender!) {
    ...SiteInfo
    results: postgres {
      notes: allNotesList(
        filter: { genders: { contains: [ $genderId ] }}
        orderBy: ID_ASC,
      ) {
        ...Note
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { notes } = data.results
  const { entity } = meta

  const page = `${entity.singular} Notes Sitemap`
  const title = `${page} - Sitemaps`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: 'Sitemap',
      slug: links.sitemap.home,
    },
    {
      name: 'Notes',
      slug: links.sitemap.notes,
    }
  ]
  const schema = {
    breadcrumb: trail,
    description: title,
    keywords,
    name: title,
    slug: links.sitemap.notes,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema} title={title} />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <List>
        {notes.map(note => (
          <ListItem key={note.id}>
            <Link href={note.slug}>
              {note.name} Note
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
